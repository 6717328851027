body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .expanded-left {
  margin-left: 30px !important;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-float infinite 3s ease-in-out; */
    height: 102.5%;
    width: 102.5%;
  }
}

.editable-cell {
  position: relative;
  z-index: 1000;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  z-index: 1000;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.disabled{
  pointer-events: none;
  padding-left: 48px;
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  text-decoration: line-through;
}

.headerLogo {
  height: 100%; 
  width: 100%; 
  object-fit: contain;
}

.header {   
  background-color: var(--theme-background) !important;  
  border-Bottom: 0.1em solid var(--theme-main-color);
  padding-left: 25px !important;  
  padding-right: 25px !important;  
}

.headerDivider {
  color: orange;
}

.App-header-right {
  background-color: whitesmoke;  
}

.App-header-left h1 {
  color: aliceblue;
}

.footer {
  padding: 0px;   
  position: relative;   
  background-color: var(--theme-background) !important;
  border-Top: 0.1em solid var(--theme-main-color);  
}

.content {
  background-color: white !important;  
    min-height: calc(100vh - 34px);  
}

.main {
  background-color: white !important;  
  min-height: calc(100vh - 34px);  
}

.full-content {
  background-color: white !important;  
}

.ant-alert-error{
  margin-top: 5px !important;
}

.ant-timeline-item-content{
  width: calc(150%) !important;
}

.grifo-footer {
  padding: 0px;   
}

.App-link {
  color: rgb(112, 76, 182);
}

.ant-breadcrumb {
  background-color: var(--theme-background) !important;
  color: var(--theme-main-color) !important;
  border-color: #1861ff2f !important;
  border-bottom-width: 1px;
  border-bottom-style: solid;  
}

/* .ant-ribbon {
  background-color: var(--theme-main-color) !important;  
} */

/* .ant-scroll-number {
  background-color: var(--theme-main-color) !important;  
} */

/* .ant-avatar {
  background-color: var(--theme-main-color) !important;  
} */

.ant-pagination-item-active {
  border-color: var(--theme-main-color) !important;  
}

.ant-pagination-item-active a {
  color: var(--theme-main-color) !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-main-color) !important;
}

.ant-tabs-ink-bar {
  background-color: var(--theme-main-color) !important;
}

.ant-menu {
  background-color: var(--theme-background) !important;
}

.ant-menu-title-content {
  color: var(--theme-main-color) !important;  
}

.ant-menu-title-content .ant-typography {
  color: var(--theme-main-color) !important;  
}

.ant-menu-title-content a {
  color: var(--theme-main-color) !important;  
}

.ant-menu-item::after {
  border-right-color: var(--theme-main-color) !important;  
}

.ant-menu-submenu-selected {
  background-color: var(--theme-background) !important;   
}

.ant-menu-submenu-selected .ant-menu-title-content span {
  font-weight: bold;
}

.ant-menu-item-selected {
  font-weight: bold;
  background-color: var(--theme-main-color-faded) !important;
}

.ant-menu-submenu .ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected div span {
  font-weight: bold;
}

a.ant-typography {
  color: var(--theme-main-color) !important;  
}

a {
  color: var(--theme-main-color) !important;  
}

/* .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--theme-main-color) !important; 
} */

.ant-modal-title {
  color: var(--theme-main-color) !important;  
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {
  color: var(--theme-main-color) !important;  
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;  
}

.ant-table-row {
  vertical-align: initial !important;
}

.ant-divider {
  color: var(--theme-main-color) !important;  
}

.ant-divider-horizontal {
  border-color: var(--theme-main-color-faded) !important;
}

.ant-divider-horizontal.ant-divider-with-text::after {
  border-color: var(--theme-main-color-faded) !important;
}

.ant-divider-horizontal.ant-divider-with-text::before {
  border-color: var(--theme-main-color-faded) !important;  
}

.ant-btn-primary {
  background-color: var(--theme-main-color) !important;  
  color: var(--theme-secondary-color) !important;  
  border-color: var(--theme-secondary-color) !important;  
}

.ant-steps-item-title {
  color: var(--theme-main-color) !important;  
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: var(--theme-main-color) !important;  
  color: var(--theme-secondary-color) !important;  
  border-color: var(--theme-secondary-color) !important;  
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  color: var(--theme-secondary-color) !important;  
  border-color: var(--theme-secondary-color) !important;  
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-icon {
  color: var(--theme-main-color) !important;  
}

.ant-switch-checked {
  background-color: var(--theme-main-color) !important;    
}

.ant-radio-button-checked {
  background-color: var(--theme-main-color) !important;  
  border-color: var(--theme-secondary-color) !important;  
}

.ant-layout-sider-dark {
  background-color: var(--theme-background) !important;  
  border-right: 0.1em solid var(--theme-main-color);
  height: 100%;
  min-height: 66vh;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

ul {
  padding: 0;
  list-style-type: none;  
}

li {
  padding: 0;
}

.footerli {
  padding: 0;
  line-height: 13px;
}

.Table--hide-expand .ant-table-row-expand-icon {
  display: none;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}
.root {
  height: 100% !important;
}

.wrapper {
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto -155px; /* the bottom margin is the negative value of the footer's height */
}

.my-row {
  background-color: white;
  transition: background-color 0.5s ease;
}

.highlighted {
  background-color: aliceblue;
}

.flash {
  animation: flash 0.5s ease;
}

@keyframes flash {
  from {background-color: white;}
  to {background-color: aliceblue;}
}